import { HttpHeader } from '@solidjs/start';
import { Show, onMount } from 'solid-js';
import {
  BandComponent,
  searchParam,
} from '~/components/designer2/BandComponent';
import { LogComponent } from '~/components/designer2/LogComponent';

export default function Home() {
  const showDebugConsole = searchParam('console', 'c');
  const isIFrame = searchParam('iframe', 'i');
  onMount(() => {
    if (isIFrame) {
      // remove the menu toolbar at the top
      for (const header of document.getElementsByTagName('header')) {
        header.style.display = 'none';
      }
    }
  });
  return (
    // *INDENT-OFF*
    <main class="text-center p-4">
      <HttpHeader name="Cross-Origin-Embedder-Policy" value="require-corp" />
      <HttpHeader name="Cross-Origin-Opener-Policy" value="same-origin" />
      <HttpHeader name="Cross-Origin-Resource-Policy" value="cross-origin" />
      <Show when={isIFrame === false}>
        <div id="space_for_floating_menubar" style="height:48px;" />
      </Show>
      <BandComponent />
      <br />
      <Show when={showDebugConsole === true}>
        <LogComponent level="5" nlines="0" maxlength="0" />
      </Show>
    </main>
    // *INDENT-ON*
  );
}
